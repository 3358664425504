import { useCallback } from 'react';

import type { AccessFn } from '@studio/api/access';

import useAccessContext from './useAccessContext';

export type AccessChecker = (access: AccessFn) => boolean;

export default function useAccessChecker(): AccessChecker {
  const accessContext = useAccessContext();
  return useCallback(access => access(accessContext), [accessContext]);
}

import type { ContentPromotionFeature } from './content-promotion/contentPromotionfeatures';
import contentPromotionRules from './content-promotion/contentPromotionRules';
import type { CurationPromotionFeature } from './curation-promotion/curationPromotionFeatures';
import curationPromotionRules from './curation-promotion/curationPromotionRules';
import { SonicFeature } from './features';
import { Role } from './roles';
import { and, not, or, ref as rawRef, type Rule } from './RuleEngine';
import type { AccessContext } from './utils';

// We can't do this unfortunately, since it triggers a recursion error
// const ref = rawRef<keyof typeof rules>;
const ref = rawRef<
  // Feature rule names
  | SonicFeature
  | ContentPromotionFeature
  | CurationPromotionFeature
  // Role rule names
  | keyof typeof roleRules
  // Any other string starting with either "has" or "can"
  | `has${string}`
  | `can${string}`
  | `is${string}`
>;

export const roleRules = {
  isSuperAdmin: { roles: Role.Admin },
  isEditor: { roles: Role.Editor },
  isUsersAdmin: { roles: Role.UsersAdmin },
  isCustomerSupport: { roles: Role.CustomerSupport },
  isTaxonomyManager: { roles: Role.TaxonomyManager },
  isSonicStudioBetaTester: { roles: Role.SonicStudioBetaTester },
  isSonicDeveloper: { roles: Role.SonicDeveloper },
  isSiteBuilderManager: { roles: Role.SiteBuilderManager },
  isSiteConfigManager: { roles: Role.SiteConfigManager },
  isCapabilityManager: { roles: Role.CapabilityManager },
  isIpWhitelister: { roles: Role.IpWhitelister },
  isTestSubscriptionsManager: { roles: Role.TestSubscriptionsManager },
  isGeoOverrider: { roles: Role.GeoOverrider },
  isCampaignManager: { roles: Role.CampaignManager },
  isProductManager: { roles: Role.ProductManager },
  isLabsManager: { roles: Role.LabsManager },
  isLabsViewer: { roles: Role.LabsViewer },
  isLabsAdmin: { roles: Role.LabsAdmin },
  isLegalManager: { roles: Role.LegalManager },
  isTemplateManager: { roles: Role.TemplateManager },
  isRARApprover: { roles: Role.RARApprover },
  isAccountingEditor: { roles: Role.AccountingEditor },
  isContractManager: { roles: Role.ContractManager },
  isBusinessReader: { roles: Role.BusinessReader },
  isEditorialReader: { roles: Role.EditorialReader },
  isContentPlayer: { roles: Role.ContentPlayer },
  isProductLineEditor: { roles: Role.ProductLineEditor },
  isPPVManager: { roles: Role.PPVManager },
  isMultiverseAdmin: { roles: Role.MultiverseAdmin },
  isMultiverse: { roles: Role.Multiverse },
  isDianaCategoryManager: { roles: Role.DianaCategoryManager },
  isDianaOverrideManager: { roles: Role.DianaOverrideManager },
  isDianaOverridePublisher: { roles: Role.DianaOverridePublisher },
  isGlobalEditor: { roles: Role.EditorGlobal },
  isAPACEditor: { roles: Role.EditorAPAC },
  isEMEAEditor: { roles: Role.EditorEMEA },
  isLATAMEditor: { roles: Role.EditorLATAM },
  isUCANEditor: { roles: Role.EditorUCAN },
  isLocalizationManager: { roles: Role.LocalizationManager },
  isGSMUIApprover: { roles: Role.GSMUIApprover },
  isSegmentManager: { roles: Role.SegmentManager },
  isStudioUserAdmin: { roles: Role.StudioUserAdmin },
  isPartnerIntegrationManager: { roles: Role.PartnerIntegrationManager },
  isStudioIdentityTester: { roles: Role.StudioIdentityTester },
  isMultiConfigAdmin: { roles: Role.MultiConfigAdmin },
  isMultiConfigGeographicManager: { roles: Role.MultiConfigGeographicManager },
  isMultiConfigRatingsManager: { roles: Role.MultiConfigRatingsManager },
  isMosaicMartechOperator: { roles: Role.MosaicMartechOperator },
  isMosaicMartechOnLooker: { roles: Role.MosaicMartechOnLooker },
  isMosaicDataEngineeringAdmin: { roles: Role.MosaicDataEngineerAdmin },
} as const satisfies Record<`is${Capitalize<string>}`, Rule<AccessContext>>;

export const featureFlagRules = {
  [SonicFeature.FullStory]: { realm: 'dplay', env: 'test' },
  [SonicFeature.ContentInsight]: or(
    { studioExperience: 'max' },
    { studioExperience: 'discoveryplus' },
  ),
  [SonicFeature.LocalizationReporting]: or(
    { studioExperience: 'max' },
    { studioExperience: 'discoveryplus' },
  ),
  [SonicFeature.StudioAccess]: { realm: 'bolt' },
  [SonicFeature.ContentVisualization]: and(
    { realm: 'bolt' },
    or({ studioExperience: 'max' }, { studioExperience: 'discoveryplus' }),
  ),
  [SonicFeature.GeneralRights]: or(
    { realm: 'dkidses' },
    { realm: 'dkidsonpt' },
    { realm: 'dkidspt' },
    { realm: 'dmaxde' },
    { realm: 'dplayjp' },
    { realm: 'factual' },
    { realm: 'foodnetwork' },
    { realm: 'hgtv' },
    { realm: 'loma' },
    { realm: 'luna' },
    { realm: 'lunadev' },
    { realm: 'tlcde' },
  ),
  [SonicFeature.Genres]: or(
    { realm: 'dkidses' },
    { realm: 'dkidsonpt' },
    { realm: 'dkidspt' },
    { realm: 'dmaxde' },
    { realm: 'dplayjp' },
    { realm: 'dplusindia' },
    { realm: 'eurosport' },
    { realm: 'factual' },
    { realm: 'foodnetwork' },
    { realm: 'gcn' },
    { realm: 'hgtv' },
    { realm: 'loma' },
    { realm: 'luna' },
    { realm: 'lunadev' },
    { realm: 'motortrend' },
    { realm: 'tlcde' },
  ),
  [SonicFeature.Images]: or(
    not(ref(SonicFeature.StudioExperience)),
    { studioExperience: 'max' },
    { studioExperience: 'discoveryplus' },
  ),
  [SonicFeature.IMSForContentRatings]: or(
    { realm: 'dplay' },
    { realm: 'dplayjp' },
    { realm: 'dplusapac' },
    { realm: 'dplusindia' },
    { realm: 'eurosport' },
    { realm: 'gcn' },
    { realm: 'go' },
    { realm: 'magnolia' },
    { realm: 'motortrend' },
    { realm: 'bolt' },
  ),
  [SonicFeature.IMSForContentDescriptors]: or(
    { realm: 'dplay' },
    { realm: 'dplayjp' },
    { realm: 'dplusapac' },
    { realm: 'dplusindia' },
    { realm: 'eurosport' },
    { realm: 'gcn' },
    { realm: 'go' },
    { realm: 'magnolia' },
    { realm: 'motortrend' },
    { realm: 'bolt' },
  ),
  [SonicFeature.IMSForGenres]: or(
    { realm: 'dkidses' },
    { realm: 'dkidsonpt' },
    { realm: 'dkidspt' },
    { realm: 'dmaxde' },
    { realm: 'dplayjp' },
    { realm: 'hgtv' },
    { realm: 'loma' },
    { realm: 'motortrend' },
    { realm: 'tlcde' },
    { realm: 'bolt' },
  ),
  [SonicFeature.IMSForLivestreams]: or(
    { realm: 'dkidses' },
    { realm: 'dkidsonpt' },
    { realm: 'dkidspt' },
    { realm: 'dmaxde' },
    { realm: 'dplayjp' },
    { realm: 'hgtv' },
    { realm: 'loma' },
    { realm: 'tlcde' },
    { realm: 'lunadev' },
    { realm: 'bolt' },
  ),
  [SonicFeature.IMSForTaxonomyNodes]: or(
    { realm: 'dplay' },
    { realm: 'dplayjp' },
    { realm: 'dplusapac' },
    { realm: 'dplusindia' },
    { realm: 'eurosport' },
    { realm: 'gcn' },
    { realm: 'go' },
    { realm: 'magnolia' },
    { realm: 'motortrend' },
    { realm: 'lunadev' },
    { realm: 'bolt' },
  ),
  [SonicFeature.IMSForVideoAssets]: or(
    { realm: 'dkidses' },
    { realm: 'dkidsonpt' },
    { realm: 'dkidspt' },
    { realm: 'dmaxde' },
    { realm: 'dplayjp' },
    { realm: 'hgtv' },
    { realm: 'loma' },
    { realm: 'tlcde' },
    { realm: 'lunadev' },
    { realm: 'bolt' },
  ),
  [SonicFeature.ListableAndPlayableAppNames]: or(
    { realm: 'dplay' },
    { realm: 'dplusapac' },
    { realm: 'eurosport' },
    { realm: 'go' },
  ),
  [SonicFeature.ListableAndPlayableTerritories]: or(
    { realm: 'dplay' },
    { realm: 'dplayjp' },
    { realm: 'dplusapac' },
    { realm: 'dplusindia' },
    { realm: 'eurosport' },
    { realm: 'gcn' },
    { realm: 'go' },
    { realm: 'magnolia' },
    { realm: 'motortrend' },
  ),
  [SonicFeature.LiveVideos]: or({ realm: 'dplay' }, { realm: 'eurosport' }),
  [SonicFeature.Localizations]: or(
    { realm: 'dplay' },
    { realm: 'dplusapac' },
    { realm: 'dplusindia' },
    { realm: 'eurosport' },
    { realm: 'gcn' },
    { realm: 'go' },
    { realm: 'magnolia' },
    { realm: 'motortrend' },
    { realm: 'bolt' },
  ),
  [SonicFeature.MediaDownload]: or(
    { realm: 'dkidses' },
    { realm: 'dkidsonpt' },
    { realm: 'dkidspt' },
    { realm: 'dmaxde' },
    { realm: 'dplay' },
    { realm: 'dplayjp' },
    { realm: 'dplusapac' },
    { realm: 'factual' },
    { realm: 'foodnetwork' },
    { realm: 'go' },
    { realm: 'hgtv' },
    { realm: 'loma' },
    { realm: 'lunadev' },
    { realm: 'magnolia' },
    { realm: 'tlcde' },
  ),
  [SonicFeature.Offers]: or(
    { realm: 'dplay', env: 'test' },
    { realm: 'eurosport' },
    { realm: 'go', env: 'test' },
    { realm: 'motortrend' },
    { realm: 'bolt' },
  ),
  [SonicFeature.OffersReadOnly]: or(
    { realm: 'dplay', env: 'prod' },
    { realm: 'go', env: 'prod' },
    { realm: 'bolt' },
  ),
  [SonicFeature.OTP]: {
    realm: 'dplusindia',
  },
  [SonicFeature.PersonalizedAIRecommendations]: or(
    { realm: 'dplay' },
    { realm: 'dplusapac' },
    { realm: 'go' },
    { realm: 'motortrend' },
    { realm: 'bolt' },
  ),
  [SonicFeature.HopperRecommendations]: or(
    and({ realm: 'bolt', studioExperience: 'discoveryplus' }),
    and({ realm: 'bolt', studioExperience: 'max' }, not({ env: 'prod' })),
  ),
  [SonicFeature.ProductLineFilterForTaxonomyNodeImg]: {
    realm: 'bolt',
  },
  [SonicFeature.WatchAgainKind]: {
    realm: 'bolt',
  },
  [SonicFeature.Providers]: or(
    { realm: 'dkidses' },
    { realm: 'dkidsonpt' },
    { realm: 'dkidspt' },
    { realm: 'dmaxde' },
    { realm: 'dplayjp' },
    { realm: 'factual' },
    { realm: 'foodnetwork' },
    { realm: 'hgtv' },
    { realm: 'loma' },
    { realm: 'luna' },
    { realm: 'lunadev' },
    { realm: 'tlcde' },
  ),
  [SonicFeature.RelatedSiteBuilderObjects]: or(
    { realm: 'dkidses' },
    { realm: 'dkidsonpt' },
    { realm: 'dkidspt' },
    { realm: 'dmaxde' },
    { realm: 'dplayjp' },
    { realm: 'factual' },
    { realm: 'foodnetwork' },
    { realm: 'hgtv' },
    { realm: 'loma' },
    { realm: 'luna' },
    { realm: 'lunadev' },
    { realm: 'tlcde' },
  ),
  [SonicFeature.SourceSystemId]: or(
    { realm: 'dplay' },
    { realm: 'dplusapac' },
    { realm: 'dplusindia' },
    { realm: 'eurosport' },
    { realm: 'gcn' },
    { realm: 'go' },
    { realm: 'magnolia' },
    { realm: 'motortrend' },
  ),
  [SonicFeature.Subtitles]: or(
    { realm: 'dkidses' },
    { realm: 'dkidsonpt' },
    { realm: 'dkidspt' },
    { realm: 'dmaxde' },
    { realm: 'dplayjp' },
    { realm: 'factual' },
    { realm: 'foodnetwork' },
    { realm: 'hgtv' },
    { realm: 'loma' },
    { realm: 'luna' },
    { realm: 'lunadev' },
    { realm: 'tlcde' },
  ),
  [SonicFeature.Tags]: or(
    { realm: 'dkidses' },
    { realm: 'dkidsonpt' },
    { realm: 'dkidspt' },
    { realm: 'dmaxde' },
    { realm: 'dplayjp' },
    { realm: 'factual' },
    { realm: 'foodnetwork' },
    { realm: 'hgtv' },
    { realm: 'loma' },
    { realm: 'luna' },
    { realm: 'lunadev' },
    { realm: 'tlcde' },
  ),
  [SonicFeature.Taxonomies]: or(
    { realm: 'dplay' },
    { realm: 'dplayjp' },
    { realm: 'dplusapac' },
    { realm: 'dplusindia' },
    { realm: 'eurosport' },
    { realm: 'gcn' },
    { realm: 'go' },
    { realm: 'luna' },
    { realm: 'lunadev' },
    { realm: 'magnolia' },
    { realm: 'motortrend' },
    { studioExperience: 'max' },
    { studioExperience: 'discoveryplus' },
  ),
  [SonicFeature.TVListings]: or(
    { realm: 'dplay' },
    { realm: 'dplusapac' },
    { realm: 'go' },
  ),
  [SonicFeature.UserCapabilitiesV1]: or(
    { realm: 'dkidses' },
    { realm: 'dkidsonpt' },
    { realm: 'dkidspt' },
    { realm: 'dplay' },
    { realm: 'dplusapac' },
    { realm: 'dplusindia' },
    { realm: 'eurosport' },
    { realm: 'factual' },
    { realm: 'foodnetwork' },
    { realm: 'gcn' },
    { realm: 'go' },
    { realm: 'magnolia' },
    { realm: 'motortrend' },
  ),
  [SonicFeature.UserCapabilitiesV2]: or(
    { studioExperience: 'max' },
    { studioExperience: 'discoveryplus' },
  ),
  [SonicFeature.VideoAssetUpload]: or(
    { realm: 'dkidses' },
    { realm: 'dkidsonpt' },
    { realm: 'dkidspt' },
    { realm: 'dmaxde' },
    { realm: 'dplay' },
    { realm: 'dplayjp' },
    { realm: 'factual' },
    { realm: 'foodnetwork' },
    { realm: 'hgtv' },
    { realm: 'loma' },
    { realm: 'luna' },
    { realm: 'lunadev' },
    { realm: 'tlcde' },
  ),
  [SonicFeature.VMS]: or(
    { realm: 'dkidses' },
    { realm: 'dkidsonpt' },
    { realm: 'dkidspt' },
    { realm: 'dmaxde' },
    { realm: 'dplay' },
    { realm: 'dplayjp' },
    { realm: 'dplusapac' },
    { realm: 'dplusindia' },
    { realm: 'eurosport' },
    { realm: 'factual' },
    { realm: 'gcn' },
    { realm: 'go' },
    { realm: 'hgtv' },
    { realm: 'loma' },
    { realm: 'luna' },
    { realm: 'lunadev' },
    { realm: 'motortrend' },
    { realm: 'tlcde' },
  ),
  [SonicFeature.MainTerritoryCodeAndBrandIdOnTermKinds]: or(
    { realm: 'dplay' },
    { realm: 'dplusapac' },
    { realm: 'go' },
    { realm: 'magnolia' },
    { realm: 'bolt' },
  ),
  [SonicFeature.ContentProtection]: or(
    { realm: 'dplusapac' },
    { realm: 'gcn' },
    { realm: 'go' },
    { realm: 'motortrend' },
    { realm: 'dplay' },
    { realm: 'eurosport' },
  ),
  [SonicFeature.DTCRARContracts]: or(
    { realm: 'dplay', env: 'test' },
    { realm: 'dplay', env: 'prod' },
    { realm: 'dplusindia', env: 'test' },
    { realm: 'eurosport', env: 'test' },
    { realm: 'foodnetwork', env: 'test' },
    { realm: 'gcn', env: 'test' },
    { realm: 'go' },
  ),
  [SonicFeature.DTCRARProducts]: or(
    { realm: 'dplay' },
    { realm: 'dplusapac' },
    { realm: 'dplusindia' },
    { realm: 'eurosport' },
    { realm: 'foodnetwork' },
    { realm: 'gcn' },
    { realm: 'go' },
    { realm: 'lunadev', env: 'test' },
    { realm: 'motortrend' },
    { realm: 'bolt' },
  ),
  [SonicFeature.SiteBuilderLegacy]: or(
    { realm: 'dkidses' },
    { realm: 'dkidsonpt' },
    { realm: 'dkidspt' },
    { realm: 'dmaxde' },
    { realm: 'dplay' },
    { realm: 'dplayjp' },
    { realm: 'dplusapac' },
    { realm: 'dplusindia' },
    { realm: 'eurosport' },
    { realm: 'factual' },
    { realm: 'gcn' },
    { realm: 'go' },
    { realm: 'hgtv' },
    { realm: 'loma' },
    { realm: 'luna' },
    { realm: 'lunadev' },
    { realm: 'motortrend' },
    { realm: 'tlcde' },
  ),
  [SonicFeature.SiteBuilderBolt]: or(
    { studioExperience: 'max' },
    { studioExperience: 'discoveryplus' },
  ),

  [SonicFeature.LookerLinks]: { realm: 'bolt', env: 'prod' },

  [SonicFeature.PricePlanProvidersWhenOngoing]: or(
    { env: 'test' },
    { realm: 'go' },
  ),
  [SonicFeature.ProviderAndSKUWhenSAMIntegration]: not({ realm: 'bolt' }),
  [SonicFeature.MergedCollections]: or(
    { realm: 'go' },
    { realm: 'dplay' },
    { realm: 'bolt' },
  ),
  [SonicFeature.SubscriptionJourney]: or(
    { realm: 'go' },
    { stackName: 'sonic-test' },
    or({ studioExperience: 'discoveryplus' }, { studioExperience: 'max' }),
  ),
  [SonicFeature.ModelComparison]: or(
    { studioExperience: 'discoveryplus' },
    { studioExperience: 'max' },
  ),
  [SonicFeature.ModelComparisonShowTypeFilter]: { realm: 'bolt' },
  [SonicFeature.Pinning]: or({ realm: 'go', env: 'test' }, { realm: 'bolt' }),
  [SonicFeature.CompassReranking]: or(
    { realm: 'go', publishingSite: 'dplus_us' },
    { realm: 'dkidses', env: 'test' },
    { realm: 'dkidsonpt', env: 'test' },
    { realm: 'dkidspt', env: 'test' },
    { realm: 'dmaxde', env: 'test' },
    { realm: 'dplay', env: 'test' },
    { realm: 'dplayjp', env: 'test' },
    { realm: 'dplusapac', env: 'test' },
    { realm: 'dplusindia', env: 'test' },
    { realm: 'eurosport', env: 'test' },
    { realm: 'factual', env: 'test' },
    { realm: 'gcn', env: 'test' },
    { realm: 'go', env: 'test' },
    { realm: 'hgtv', env: 'test' },
    { realm: 'loma', env: 'test' },
    { realm: 'luna', env: 'test' },
    { realm: 'lunadev', env: 'test' },
    { realm: 'motortrend', env: 'test' },
    { realm: 'tlcde', env: 'test' },
    { realm: 'productlineplaceholder', env: 'test' },
    { realm: 'bolt' },
  ),
  [SonicFeature.PricePlanFilteringByBrand]: or(
    { realm: 'go' },
    { realm: 'bolt' },
  ),
  [SonicFeature.PricePlanSourceSystem]: { realm: 'bolt' },
  [SonicFeature.PricePlanMarketingInfoCharacterLimit]: { realm: 'bolt' },
  [SonicFeature.AudienceTargeting]: or(
    { stackName: 'us1-test' },
    { stackName: 'eu1-test' },
    { stackName: 'sonic-test' },
    { realm: 'bolt' },
    { realm: 'dplay' },
    { realm: 'go' },
    { realm: 'eurosport' },
  ),
  [SonicFeature.AudienceTargetingFromCustomerSegments]: and(
    ref(SonicFeature.AudienceTargeting),
    or({ studioExperience: 'max' }, { studioExperience: 'discoveryplus' }),
  ),
  [SonicFeature.GauthPartners]: or(
    { realm: 'go', stackName: 'us1-test' },
    { realm: 'lunadev', stackName: 'eu1-test' },
    { realm: 'dplay' },
    { stackName: 'sonic-test' },
  ),

  // Site Builder Templates Rules
  // -- Unsure why these are split
  [SonicFeature.SiteBuilderTemplates]: or(
    { realm: 'go' },
    { realm: 'dplay' },
    { realm: 'bolt' },
  ),
  [SonicFeature.SiteBuilderTemplatesUsage]: { realm: 'bolt' },

  [SonicFeature.DetokenizePaymentMethod]: {
    realm: 'dplusindia',
  },
  [SonicFeature.PayPerViewLegacy]: or(
    { realm: 'dplay' },
    { stackName: 'sonic-test' },
  ),
  [SonicFeature.PayPerViewBolt]: { realm: 'bolt', env: 'test' },
  [SonicFeature.BoltSubscriptionJourney]: { realm: 'bolt' },
  [SonicFeature.TerminateReasonNorwayDenmark]: {
    realm: 'dplay',
  },
  [SonicFeature.UserCommunication]: or(
    { realm: 'dkidses' },
    { realm: 'dkidsonpt' },
    { realm: 'dkidspt' },
    { realm: 'dmaxde' },
    { realm: 'dplay' },
    { realm: 'dplusapac' },
    { realm: 'dplusindia' },
    { realm: 'eurosport' },
    { realm: 'factual' },
    { realm: 'gcn' },
    { realm: 'go' },
    { realm: 'hgtv' },
    { realm: 'loma' },
    { realm: 'luna' },
    { realm: 'lunadev' },
    { realm: 'motortrend' },
    { realm: 'tlcde' },
  ),
  [SonicFeature.Legal]: or(
    { realm: 'dkidses' },
    { realm: 'dkidsonpt' },
    { realm: 'dkidspt' },
    { realm: 'dmaxde' },
    { realm: 'dplay' },
    { realm: 'dplusapac' },
    { realm: 'dplusindia' },
    { realm: 'eurosport' },
    { realm: 'factual' },
    { realm: 'gcn' },
    { realm: 'go' },
    { realm: 'hgtv' },
    { realm: 'loma' },
    { realm: 'luna' },
    { realm: 'lunadev' },
    { realm: 'motortrend' },
    { realm: 'tlcde' },
    { realm: 'bolt' },
  ),
  [SonicFeature.Ratings]: or(
    { realm: 'dkidses' },
    { realm: 'dkidsonpt' },
    { realm: 'dkidspt' },
    { realm: 'dmaxde' },
    { realm: 'dplay' },
    { realm: 'dplusapac' },
    { realm: 'dplusindia' },
    { realm: 'eurosport' },
    { realm: 'factual' },
    { realm: 'gcn' },
    { realm: 'go' },
    { realm: 'hgtv' },
    { realm: 'loma' },
    { realm: 'luna' },
    { realm: 'lunadev' },
    { realm: 'motortrend' },
    { realm: 'tlcde' },
    { studioExperience: 'discoveryplus' },
    { studioExperience: 'max' },
  ),
  [SonicFeature.LabsBolt]: { realm: 'bolt' },
  [SonicFeature.LabsLegacy]: not({ realm: 'bolt' }),
  [SonicFeature.Packages]: or(
    { realm: 'bolt' },
    { realm: 'dkidses' },
    { realm: 'dkidsonpt' },
    { realm: 'dkidspt' },
    { realm: 'dmaxde' },
    { realm: 'dplay' },
    { realm: 'dplusapac' },
    { realm: 'dplusindia' },
    { realm: 'eurosport' },
    { realm: 'factual' },
    { realm: 'foodnetwork' },
    { realm: 'gcn' },
    { realm: 'go' },
    { realm: 'hgtv' },
    { realm: 'luna' },
    { realm: 'lunadev' },
    { realm: 'magnolia' },
    { realm: 'motortrend' },
    { realm: 'tlcde' },
  ),
  [SonicFeature.ShowTypes]: { realm: 'bolt' },
  [SonicFeature.PushPinReranking]: or(
    { realm: 'bolt' },
    { realm: 'productlineplaceholder', env: 'test' },
  ),
  [SonicFeature.PackagesInCapabilities]: { realm: 'bolt' },
  [SonicFeature.PartnerSubscriptions]: { realm: 'bolt' },
  [SonicFeature.BoltCapabilities]: { realm: 'bolt' },
  [SonicFeature.CapabilitiesBolt]: { realm: 'bolt' },
  [SonicFeature.CapabilitiesLegacy]: not({ realm: 'bolt' }),
  [SonicFeature.SiteBuilderCollectionSecondaryTitle]: { realm: 'bolt' },
  [SonicFeature.SearchAutoCollection]: { realm: 'bolt' },
  [SonicFeature.SiteBuilderContinueWatching]: { realm: 'bolt' },
  [SonicFeature.SiteBuilderLegacyContinueWatching]: or(
    { realm: 'dkidses' },
    { realm: 'dkidsonpt' },
    { realm: 'dkidspt' },
    { realm: 'dmaxde' },
    { realm: 'dplay' },
    { realm: 'dplusapac' },
    { realm: 'dplusindia' },
    { realm: 'eurosport' },
    { realm: 'factual' },
    { realm: 'gcn' },
    { realm: 'go' },
    { realm: 'hgtv' },
    { realm: 'loma' },
    { realm: 'luna' },
    { realm: 'lunadev' },
    { realm: 'motortrend' },
    { realm: 'tlcde' },
  ),
  [SonicFeature.SiteBuilderMyList]: { realm: 'bolt' },
  [SonicFeature.SiteBuilderRecentlyViewed]: not({ realm: 'bolt' }),
  [SonicFeature.SiteBuilderRecommendedShows]: not({ realm: 'bolt' }),
  [SonicFeature.SiteBuilderShowsWithExpiringEpisodes]: not({ realm: 'bolt' }),
  [SonicFeature.SiteBuilderVideoScheduleHour]: not({ realm: 'bolt' }),
  [SonicFeature.SiteBuilderVideoTabbedScheduleGrid]: not({ realm: 'bolt' }),
  [SonicFeature.SiteBuilderHideKindSortOptions]: { realm: 'bolt' },
  [SonicFeature.SiteBuilderKindDisabled]: { realm: 'bolt' },
  [SonicFeature.SiteBuilderVideoListingDisabled]: { realm: 'bolt' },
  [SonicFeature.SiteBuilderFilterByTaxonomyNodes]: not({ realm: 'bolt' }),
  [SonicFeature.PartnerLinkCta]: { realm: 'bolt' },
  [SonicFeature.HorizontalReranking]: { realm: 'bolt' },
  [SonicFeature.CollectionPersonalization]: { realm: 'bolt' },
  [SonicFeature.PasswordResetCopyToClipboard]: { realm: 'bolt' },
  [SonicFeature.LegacyContentSearch]: not({ realm: 'bolt' }),
  [SonicFeature.Avatars]: or(
    { studioExperience: 'discoveryplus' },
    { studioExperience: 'max' },
  ),
  [SonicFeature.Badging]: or(
    { realm: 'bolt' },
    { realm: 'go' },
    { realm: 'dplay' },
    { realm: 'dplusapac' },
  ),
  [SonicFeature.TaxonomyUsedByTypeCVNames]: { realm: 'bolt' },
  [SonicFeature.M10nFeatures]: not({ realm: 'bolt' }),
  [SonicFeature.Search]: or(
    { stackName: 'us1-test' },
    { stackName: 'eu1-test' },
    { stackName: 'ap2-test' },
  ),
  [SonicFeature.BrazeUserCommunication]: and(
    not({ env: 'prod' }),
    or({ studioExperience: 'discoveryplus' }, { studioExperience: 'max' }),
  ),
  [SonicFeature.UserRoleChangeConfirmation]: not({ env: 'test' }),
  [SonicFeature.Hotjar]: or({ env: 'int' }, { env: 'test' }),
  [SonicFeature.Posthog]: or({ env: 'int' }, { env: 'test' }),
  [SonicFeature.GSM]: or(
    { studioExperience: 'discoveryplus' },
    { studioExperience: 'max' },
  ),
  [SonicFeature.UserIdentity]: { realm: 'bolt' },
  [SonicFeature.Collaborators]: and(
    { realm: 'bolt' },
    or({ env: 'test' }, { env: 'int' }),
  ),
  [SonicFeature.ContractWithSidebar]: { env: 'test' },
  [SonicFeature.StudioGlobalExpansion]: and(
    { realm: 'bolt' },
    not({ publishingSite: 'beam_us' }),
  ),
  [SonicFeature.RolesPermissions]: and(
    { realm: 'bolt' },
    not({ publishingSite: 'beam_us' }),
  ),
  [SonicFeature.IAPParentPricePlan]: { realm: 'bolt' },
  [SonicFeature.Multiverse]: { realm: 'bolt' },
  [SonicFeature.Plans]: { realm: 'bolt' },
  [SonicFeature.ProductVariants]: { realm: 'bolt' },
  [SonicFeature.ProductVariantsV2]: { env: 'test', realm: 'bolt' },
  [SonicFeature.Sites]: not({ realm: 'bolt' }),
  [SonicFeature.TerritoryHierarchyDefinition]: and(
    { realm: 'bolt' },
    not({ publishingSite: 'beam_us' }),
  ),
  [SonicFeature.IsPrsEnabled]: { realm: 'bolt' },
  [SonicFeature.UserDeviceInfo]: { realm: 'bolt' },
  [SonicFeature.SiteBuilderFilterByAgeGroups]: { realm: 'bolt' },
  [SonicFeature.SiteBuilderFilterByRoutes]: { realm: 'bolt' },
  [SonicFeature.SiteBuilderFilterByKind]: { realm: 'bolt' },
  [SonicFeature.SiteBuilderFilterByAudienceTargeting]: { realm: 'bolt' },
  [SonicFeature.SiteBuilderExcludeTargetForAudiences]: { realm: 'bolt' },
  [SonicFeature.SiteBuilderFilterByBrand]: { realm: 'bolt' },
  [SonicFeature.Diana]: or(
    { studioExperience: 'discoveryplus' },
    { studioExperience: 'max' },
  ),
  [SonicFeature.StudioPayments]: { realm: 'bolt' },
  [SonicFeature.StudioPaymentsRefund]: { realm: 'bolt', env: 'test' },
  [SonicFeature.StudioPaymentsItemizedRefund]: { realm: 'bolt', env: 'test' },
  [SonicFeature.StudioRolesWarning]: { realm: 'bolt' },
  [SonicFeature.AddonProducts]: { realm: 'bolt' },
  [SonicFeature.UserDownloadsInfo]: { realm: 'bolt' },
  [SonicFeature.TestSubscriptions]: { realm: 'bolt' },
  [SonicFeature.LocalizationBulkUpload]: { realm: 'bolt' },
  [SonicFeature.MonetizationLegacy]: not({ realm: 'bolt' }),
  [SonicFeature.MonetizationBolt]: or(
    { studioExperience: 'discoveryplus' },
    { studioExperience: 'max' },
  ),
  [SonicFeature.MVPDDefederation]: { realm: 'bolt' },
  [SonicFeature.EnvironmentBanner]: { realm: 'bolt' },
  [SonicFeature.Badges]: or(
    { studioExperience: 'discoveryplus' },
    { studioExperience: 'max' },
  ),
  [SonicFeature.UpdateUserMigration]: { realm: 'bolt' },
  [SonicFeature.LocalizedImages]: and(
    { realm: 'bolt' },
    not({ publishingSite: 'beam_us' }),
  ),
  [SonicFeature.LocalizedImagesAppExperience]: and(
    { realm: 'bolt' },
    not({ publishingSite: 'beam_us' }),
  ),
  [SonicFeature.VerticalPinning]: { realm: 'bolt' },
  [SonicFeature.BoltLocalization]: { realm: 'bolt' },
  [SonicFeature.PartnerMapping]: { realm: 'bolt' },
  [SonicFeature.StudioIdentity]: { realm: 'bolt' },

  [SonicFeature.DedupeCheckboxes]: { realm: 'bolt' },
  [SonicFeature.SilhouetteProfile]: { studioExperience: 'bleacherreport' },
  [SonicFeature.Multipass]: { realm: 'bolt', env: 'test' },
  [SonicFeature.ProfileSimulator]: or(
    { studioExperience: 'discoveryplus' },
    { studioExperience: 'max' },
  ),
  [SonicFeature.UserAuthProviders]: { realm: 'bolt' },
  [SonicFeature.StudioExperience]: { realm: 'bolt' },
  [SonicFeature.ElvisLegalFeatureFlag]: { realm: 'bolt' },
  [SonicFeature.Flash]: or(
    { studioExperience: 'discoveryplus' },
    { studioExperience: 'max' },
  ),
  [SonicFeature.$AppName]: { realm: 'bolt', env: 'test' },
  [SonicFeature.NewLocalizationHelperText]: or({ env: 'test' }, { env: 'int' }),
  [SonicFeature.AuthProviderVerification]: {
    studioExperience: 'bleacherreport',
  },
  [SonicFeature.Mosaic]: and({ realm: 'bolt' }),
  [SonicFeature.SiteReporting]: or(
    { studioExperience: 'discoveryplus' },
    { studioExperience: 'max' },
  ),
  [SonicFeature.Communications]: not({ realm: 'bolt' }),
  [SonicFeature.Users]: or(
    not(ref(SonicFeature.StudioExperience)),
    { studioExperience: 'discoveryplus' },
    { studioExperience: 'bleacherreport' },
    { studioExperience: 'max' },
  ),
  [SonicFeature.ExtraMemberHub]: and(
    { subdivisionMarket: 'amer' },
    { studioExperience: 'max' },
  ),
  [SonicFeature.GeoOverrides]: not({ realm: 'bolt' }),
  [SonicFeature.PlanBulkActions]: { realm: 'bolt' },
  [SonicFeature.IpWhitelist]: not({ realm: 'bolt' }),
  [SonicFeature.Multiconfig]: and({ realm: 'bolt' }, not({ env: 'prod' })),
  [SonicFeature.NewNavigation]: { realm: 'bolt', env: 'test' },
  [SonicFeature.SunsetEarly]: { realm: 'bolt' },
  [SonicFeature.PartnerPortal]: { realm: 'bolt', env: 'test' },
  [SonicFeature.NewLocalizationWidget]: and(not({ realm: 'bolt' }), {
    env: 'test',
  }),
  [SonicFeature.ExperienceUrls]: and(
    { realm: 'bolt' },
    or(
      // only dev for now
      { env: 'test' },
    ),
  ),
  // 🚨 DON'T REMOVE COMMENT : Register your new feature flag above
} as const satisfies Record<SonicFeature, Rule<AccessContext>>;

const hasAnyRole = or(...Object.values(roleRules));

export const permissionRules = {
  /*** Content Insight ***/
  canAccessContentInsight: and(
    ref(SonicFeature.ContentInsight),
    or(ref('isSuperAdmin'), ref('isEditor'), ref('isEditorialReader')),
  ),

  /*** Localization Reporting ***/
  canAccessLocalizationReporting: and(
    ref(SonicFeature.LocalizationReporting),
    or(
      ref('isSuperAdmin'),
      ref('isLocalizationManager'),
      // site builder reports
      ref('isEditor'),
      ref('isEditorialReader'),
      // subscription journey reports
      ref('isProductManager'),
      ref('isBusinessReader'),
    ),
  ),

  canAccessLocalizationBulkUpload: and(
    ref(SonicFeature.LocalizationBulkUpload),
    ref('isLocalizationManager'),
  ),

  /*** Studio Access ***/
  canAccessStudioAccess: ref(SonicFeature.StudioAccess),

  /*** Content Visualization */
  canAccessContentVisualization: and(
    ref(SonicFeature.ContentVisualization),
    or(
      ref('isSuperAdmin'),
      ref('isEditor'),
      ref('isEditorialReader'),
      and(ref('isSonicStudioBetaTester'), ref('isCustomerSupport')),
    ),
  ),

  canPlayContent: or(ref('isSuperAdmin'), ref('isContentPlayer')),

  /*** Videos ***/
  canAccessVideos: and(
    ref(SonicFeature.VMS),
    or(
      ref('isSuperAdmin'),
      ref('isCustomerSupport'),
      ref('isEditor'),
      ref('isEditorialReader'),
    ),
  ),

  canManageVideos: or(ref('isSuperAdmin'), ref('isEditor')),

  /*** Image Relationships ***/
  canAccessImageRelationships: or(
    ref('isSuperAdmin'),
    ref('isEditor'),
    ref('isProductManager'),
    ref('isCustomerSupport'),
    ref('isEditorialReader'),
    ref('isBusinessReader'),
  ),

  canManageImageRelationships: or(
    ref('isSuperAdmin'),
    ref('isEditor'),
    ref('isProductManager'),
  ),

  /*** Site Builder ***/

  canAccessSiteBuilderLegacy: and(
    ref(SonicFeature.SiteBuilderLegacy),
    or(
      ref('isSuperAdmin'),
      ref('isGlobalEditor'),
      ref('isEditor'),
      ref('isAPACEditor'),
      ref('isEMEAEditor'),
      ref('isLATAMEditor'),
      ref('isUCANEditor'),
      ref('isEditorialReader'),
      ref('isSiteConfigManager'),
    ),
  ),

  canAccessSiteBuilderBolt: and(
    ref(SonicFeature.SiteBuilderBolt),
    or(
      ref('isSuperAdmin'),
      ref('isGlobalEditor'),
      ref('isAPACEditor'),
      ref('isEMEAEditor'),
      ref('isLATAMEditor'),
      ref('isUCANEditor'),
      ref('isEditorialReader'),
      ref('isSiteConfigManager'),
    ),
  ),

  canAccessSiteBuilder: or(
    ref('canAccessSiteBuilderLegacy'),
    ref('canAccessSiteBuilderBolt'),
  ),

  canManageSiteBuilder: and(
    or(
      ref('isSuperAdmin'),
      ref('isGlobalEditor'),
      ref('isAPACEditor'),
      ref('isEMEAEditor'),
      ref('isLATAMEditor'),
      ref('isUCANEditor'),
      ref('isEditor'),
    ),
  ),

  canEditSiteBuilderConfigs: or(
    ref('isSuperAdmin'),
    ref('isSiteConfigManager'),
  ),

  canViewSiteBuilderConfigs: or(
    ref('canEditSiteBuilderConfigs'),
    ref('isEditor'),
    ref('isEditorialReader'),
  ),

  canEditSiteBuilderFeatures: and(
    ref('canAccessSiteBuilder'),
    ref('isLabsManager'),
  ),

  canUseBasicAirings: ref('isEditor'),

  canUseCompleteAirings: or(
    ref('isSuperAdmin'),
    ref('isSiteBuilderManager'),
    ref('isSonicDeveloper'),
  ),

  canEditPermissions: and(
    ref(SonicFeature.RolesPermissions),
    or(ref('isGlobalEditor'), ref('isSuperAdmin')),
  ),

  canManageSiteBuilderExperiments: and(
    ref('canAccessSiteBuilder'),
    or(ref('isLabsAdmin'), ref('isLabsManager'), ref('isSuperAdmin')),
  ),

  canEditSiteBuilderTemplates: and(
    ref(SonicFeature.SiteBuilderTemplates),
    or(ref('isSuperAdmin'), ref('isProductLineEditor')),
  ),

  canUseStudioGlobalExpansionFeatures: and(
    ref(SonicFeature.StudioGlobalExpansion),
    or(
      ref('isSuperAdmin'),
      ref('isSonicDeveloper'),
      ref('isEditor'),
      ref('isLATAMEditor'),
      ref('isEMEAEditor'),
      ref('isUCANEditor'),
      ref('isGlobalEditor'),
    ),
  ),

  canUseRolesPermissions: and(
    ref(SonicFeature.RolesPermissions),
    or(
      ref('isSuperAdmin'),
      ref('isSonicDeveloper'),
      ref('isEditor'),
      ref('isLATAMEditor'),
      ref('isEMEAEditor'),
      ref('isUCANEditor'),
      ref('isGlobalEditor'),
    ),
  ),

  canUseSiteBuilderTemplates: and(ref(SonicFeature.SiteBuilderTemplatesUsage)),

  canCreateOrEditRoutes: or(
    ref('isLATAMEditor'),
    ref('isEMEAEditor'),
    ref('isUCANEditor'),
    ref('isGlobalEditor'),
    ref('isSiteBuilderManager'),
  ),

  /*** Monetization ***/
  canManageMonetization: or(
    ref('isSuperAdmin'),
    ref('isCampaignManager'),
    ref('isProductManager'),
  ),

  canManagePricePlans: or(
    ref('isSuperAdmin'),
    ref('isProductManager'),
    ref('isCampaignManager'),
  ),

  canAccessPricePlans: and(
    ref(SonicFeature.DTCRARProducts),
    or(
      ref('canManagePricePlans'),
      ref('isCustomerSupport'),
      ref('isBusinessReader'),
    ),
  ),

  canManagePlans: and(
    ref(SonicFeature.Plans),
    or(ref('isSuperAdmin'), ref('isProductManager'), ref('isCampaignManager')),
  ),

  canAccessPlans: and(
    ref(SonicFeature.Plans),
    or(
      ref('canManagePlans'),
      ref('isCustomerSupport'),
      ref('isBusinessReader'),
    ),
  ),

  canManageProducts: and(
    ref(SonicFeature.DTCRARProducts),
    or(ref('isSuperAdmin'), ref('isProductManager')),
  ),

  canAccessProducts: and(
    ref(SonicFeature.DTCRARProducts),
    or(
      ref('canManageProducts'),
      ref('isCustomerSupport'),
      ref('isRARApprover'),
      ref('isCampaignManager'),
      ref('isAccountingEditor'),
      ref('isContractManager'),
      ref('isBusinessReader'),
    ),
  ),

  canAccessSAPMasterData: or(
    ref('isSuperAdmin'),
    ref('isRARApprover'),
    ref('isAccountingEditor'),
    ref('isContractManager'),
    ref('isProductManager'),
  ),

  canManageProductTransitions: or(ref('isSuperAdmin'), ref('isProductManager')),

  canAccessProductTransitions: and(
    ref(SonicFeature.DTCRARProducts),
    or(
      ref('canManageProductTransitions'),
      ref('isCustomerSupport'),
      ref('isCampaignManager'),
      ref('isBusinessReader'),
    ),
  ),

  canManagePackages: or(ref('isSuperAdmin'), ref('isProductManager')),

  canAccessPackages: or(
    ref('canManagePackages'),
    ref('isCustomerSupport'),
    ref('isCampaignManager'),
    ref('isRARApprover'),
    ref('isBusinessReader'),
  ),

  canManageFeatures: and(ref(SonicFeature.M10nFeatures), ref('isSuperAdmin')),

  canAccessFeatures: and(
    ref(SonicFeature.M10nFeatures),
    ref(SonicFeature.DTCRARProducts),
    or(
      ref('canManageFeatures'),
      ref('isCustomerSupport'),
      ref('isCampaignManager'),
      ref('isRARApprover'),
      ref('isProductManager'),
      ref('isBusinessReader'),
    ),
  ),

  canManageCampaigns: or(ref('isSuperAdmin'), ref('isCampaignManager')),

  canAccessCampaigns: and(
    ref(SonicFeature.DTCRARProducts),
    or(ref('canManageCampaigns'), ref('isBusinessReader')),
  ),

  canManageAffiliates: or(ref('isSuperAdmin'), ref('isContractManager')),

  canAccessAffiliates: or(
    ref('canManageAffiliates'),
    ref('isProductManager'),
    ref('isCampaignManager'),
    ref('isCustomerSupport'),
  ),

  // As per this document https://docs.google.com/document/d/1sIe26WB4_95Lxc14HwUbPPURFUFLbLaPKK4mjbyVHus/edit#heading=h.fzp0c5juhh2g
  canManageContracts: and(
    ref(SonicFeature.DTCRARContracts),
    or(
      ref('isSuperAdmin'),
      ref('isContractManager'),
      ref('isAccountingEditor'),
    ),
  ),

  canAccessContracts: and(
    ref(SonicFeature.DTCRARContracts),
    or(ref('canManageContracts'), ref('isRARApprover')),
  ),

  canAccessContractsFromProducts: and(
    ref(SonicFeature.DTCRARContracts),
    or(
      ref('canAccessContracts'),
      ref('canManageProducts'),
      ref('isRARApprover'),
    ),
  ),

  canAccessMonetization: or(
    ref('canAccessPricePlans'),
    ref('canAccessProducts'),
    ref('canAccessProductTransitions'),
    ref('canAccessPackages'),
    ref('canAccessPayPerView'),
    ref('canAccessFeatures'),
    ref('canAccessCampaigns'),
    ref('canAccessContracts'),
    ref('canAccessAffiliates'),
  ),

  canAccessMonetizationLegacy: and(
    ref(SonicFeature.MonetizationLegacy),
    ref('canAccessMonetization'),
  ),

  canAccessMonetizationBolt: and(
    ref(SonicFeature.MonetizationBolt),
    ref('canAccessMonetization'),
  ),

  /*** Users ***/
  canAccessUsers: and(
    or(
      ref('isSuperAdmin'),
      ref('isUsersAdmin'),
      ref('isCustomerSupport'),
      ref('isIpWhitelister'),
      ref('isGeoOverrider'),
      ref('isTestSubscriptionsManager'),
    ),
    ref(SonicFeature.Users),
  ),

  canCreateUser: or(
    ref('isSuperAdmin'),
    ref('isUsersAdmin'),
    ref('isCustomerSupport'),
  ),

  canViewUsers: or(
    ref('isSuperAdmin'),
    ref('isUsersAdmin'),
    ref('isCustomerSupport'),
  ),

  canSearchUsers: ref('canViewUsers'),
  canDeleteUsers: ref('canViewUsers'),
  canUpdateUsers: ref('canViewUsers'),
  hasBlockedEmailPermission: and(
    ref('canViewUsers'),
    ref(SonicFeature.UserCommunication),
  ),

  hasWhitelistPermission: and(
    ref(SonicFeature.IpWhitelist),
    or(ref('isSuperAdmin'), ref('isIpWhitelister')),
  ),

  hasGeoOverridePermission: and(
    ref(SonicFeature.GeoOverrides),
    or(ref('isSuperAdmin'), ref('isGeoOverrider')),
  ),

  canManageTestSubscriptions: and(
    ref(SonicFeature.TestSubscriptions),
    ref('isTestSubscriptionsManager'),
  ),

  canDisableMfa: ref('isSuperAdmin'),

  canManageUserSubscriptions: and(
    or(ref('isSuperAdmin'), ref('isUsersAdmin'), ref('isCustomerSupport')),
    or(
      not(ref(SonicFeature.StudioExperience)),
      not({ studioExperience: 'bleacherreport' }),
    ),
  ),

  canAccessMemberHub: and(
    ref(SonicFeature.ExtraMemberHub),
    ref('canViewUsers'),
  ),

  canAccessUserRoles: or(not({ realm: 'bolt' }), ref('isSuperAdmin')),

  canAccessUserCapabilities: and(
    or(
      ref(SonicFeature.UserCapabilitiesV1),
      ref(SonicFeature.UserCapabilitiesV2),
    ),
    or(
      ref('isSuperAdmin'),
      ref('isUsersAdmin'),
      ref('isCustomerSupport'),
      ref('isCapabilityManager'),
    ),
  ),

  canAccessUserCapabilitiesV1: and(
    ref(SonicFeature.UserCapabilitiesV1),
    and(ref('canAccessUserCapabilities')),
  ),

  canAccessUserCapabilitiesV2: and(
    ref(SonicFeature.UserCapabilitiesV2),
    ref('canAccessUserCapabilities'),
  ),

  canSearchAudit: or(
    ref('isSuperAdmin'),
    ref('isUsersAdmin'),
    ref('isCustomerSupport'),
  ),

  canManageFaultyTransactions: and(
    not(ref(SonicFeature.StudioPayments)),
    or(ref('isSuperAdmin'), ref('isCustomerSupport')),
  ),

  canAccessCommunicationHistory: or(
    ref('isSuperAdmin'),
    ref('isCustomerSupport'),
  ),

  canManageOffers: and(
    ref(SonicFeature.Offers),
    ref('canManageUserSubscriptions'),
  ),

  canAccessOffers: and(
    ref('canManageUserSubscriptions'),
    or(ref(SonicFeature.OffersReadOnly), ref('canManageOffers')),
  ),

  canAccessUserIdentity: and(
    ref(SonicFeature.UserIdentity),
    or(ref('isSuperAdmin'), ref('isUsersAdmin'), ref('isCustomerSupport')),
  ),

  /*** Labs ***/
  canManageLabsLimited: or(
    ref('isLabsAdmin'),
    ref('isLabsManager'),
    ref('isSuperAdmin'),
  ),

  canManageLabsManager: or(ref('isLabsManager'), ref('isSuperAdmin')),

  canManageLabsFull: ref('isLabsAdmin'),

  canAccessUserConsentDetails: not(ref('isCustomerSupport')),

  canAccessLabs: or(ref('canManageLabsLimited'), ref('isLabsViewer')),

  canAccessLabsBolt: and(ref(SonicFeature.LabsBolt), ref('canAccessLabs')),

  canAccessLabsLegacy: and(ref(SonicFeature.LabsLegacy), ref('canAccessLabs')),

  canViewLabsData: or(ref('isLabsViewer'), ref('canAccessLabs')),

  /*** Ratings ***/
  canAccessRatings: and(
    or(ref('isSuperAdmin'), ref('isEditor'), ref('isEditorialReader')),
    ref(SonicFeature.Ratings),
  ),
  canManageRatings: and(ref('canManageVideos'), ref(SonicFeature.Ratings)),

  /*** Taxonomy ***/
  canAccessTaxonomy: and(
    ref(SonicFeature.Taxonomies),
    or(
      ref('isSuperAdmin'),
      ref('isEditor'),
      ref('isEditorialReader'),
      ref('isTaxonomyManager'),
    ),
  ),

  canManageTaxonomy: and(
    ref('canAccessTaxonomy'),
    or(ref('isSuperAdmin'), ref('isTaxonomyManager')),
  ),

  canAccessTaxonomyRelationships: and(
    ref(SonicFeature.Taxonomies),
    or(
      ref('isSuperAdmin'),
      ref('isEditor'),
      ref('isEditorialReader'),
      ref('isCustomerSupport'),
    ),
  ),

  canEditTaxonomyRelationships: and(
    ref(SonicFeature.Taxonomies),
    ref('canManageVideos'),
  ),

  /*** Legal ***/
  canAccessLegal: and(
    ref(SonicFeature.Legal),
    or(ref('isSuperAdmin'), ref('isLegalManager')),
  ),

  /*** Communications ***/
  canAccessCommunications: and(
    ref(SonicFeature.Communications),
    or(ref('canManageCommunications'), ref('isBusinessReader')),
  ),

  canManageCommunications: or(ref('isSuperAdmin'), ref('isTemplateManager')),

  /*** Capabilities ***/
  canAccessCapabilities: and(
    or(
      ref(SonicFeature.CapabilitiesLegacy),
      ref(SonicFeature.CapabilitiesBolt),
    ),
    or(
      ref('isSuperAdmin'),
      ref('isUsersAdmin'),
      ref('isCustomerSupport'),
      ref('isCampaignManager'),
      ref('isCapabilityManager'),
      ref('isProductManager'),
      ref('isRARApprover'),
      ref('isContractManager'),
      ref('isAccountingEditor'),
      ref('isBusinessReader'),
    ),
  ),

  canAccessCapabilitiesBolt: and(
    ref(SonicFeature.CapabilitiesBolt),
    ref('canAccessCapabilities'),
  ),

  canAccessCapabilitiesLegacy: and(
    ref(SonicFeature.CapabilitiesLegacy),
    ref('canAccessCapabilities'),
  ),

  canManageCapabilities: and(
    ref('canAccessCapabilities'),
    or(ref('isSuperAdmin'), ref('isCapabilityManager')),
  ),

  canAccessPPVEntitlements: and(
    ref('canAccessUserCapabilities'),
    ref('canAccessPayPerView'),
  ),

  canManagePPVEntitlements: and(
    ref('canManageCapabilities'),
    ref('canManagePayPerView'),
  ),

  /*** Settings ***/
  canAccessSettings: or(
    ref('isSuperAdmin'),
    ref('isSonicDeveloper'),
    ref('isProductManager'),
    ref('isCampaignManager'),
    ref('isCapabilityManager'),
    ref('isRARApprover'),
    ref('isContractManager'),
    ref('isAccountingEditor'),
    ref('isUsersAdmin'),
    ref('isEditor'),
    ref('isEditorialReader'),
    ref('isBusinessReader'),
  ),

  canLocalizeContentRestrictions: and(
    ref('canAccessSettings'),
    or(ref('isEditor'), ref('isSuperAdmin'), ref('isSonicDeveloper')),
  ),

  canAccessSiteSetupWizard: or(
    ref('isSuperAdmin'),
    and(ref('isProductManager'), ref('isSonicStudioBetaTester')),
  ),

  /*** Search ***/
  canAccessSearch: and(
    or(ref('isSuperAdmin'), ref('isSonicDeveloper')),
    ref(SonicFeature.Search),
  ),

  /*** Images ***/
  canAccessImages: and(
    or(ref('isSuperAdmin'), ref('isSonicStudioBetaTester')),
    ref(SonicFeature.Images),
  ),

  /*** Sandbox ***/
  canAccessSonicStudioSandbox: ref('isSonicDeveloper'),

  /* Subscription Journey */
  canManageSubscriptionJourney: and(
    ref(SonicFeature.SubscriptionJourney),
    or(ref('isSuperAdmin'), ref('isProductManager')),
  ),

  canAccessSubscriptionJourney: and(
    ref(SonicFeature.SubscriptionJourney),
    or(ref('canManageSubscriptionJourney'), ref('isBusinessReader')),
  ),

  canManageSubscriptionJourneyExperiments: and(
    ref(SonicFeature.SubscriptionJourney),
    ref('canViewLabsData'),
    ref('canManageSubscriptionJourney'),
  ),

  canManageMarketingInfo: and(
    ref(SonicFeature.SubscriptionJourney),
    or(ref('canManageSubscriptionJourney'), ref('isCampaignManager')),
  ),

  canAccessMarketingInfo: and(
    ref(SonicFeature.SubscriptionJourney),
    or(ref('canManageMarketingInfo'), ref('isBusinessReader')),
  ),

  canAccessProductVariantsMarketing: and(
    ref('canAccessMarketingInfo'),
    ref(SonicFeature.ProductVariants),
  ),

  canAccessUserAddonSubscriptions: and(
    ref(SonicFeature.AddonProducts),
    ref('canManageUserSubscriptions'),
  ),

  canAccessAudits: or(ref('isSuperAdmin'), ref('isCustomerSupport')), // TODO: checks who should have a visibility to audits

  /*** ModelComparison ***/
  canAccessModelComparison: and(
    ref(SonicFeature.ModelComparison),
    or(
      ref('isSuperAdmin'),
      ref('isEditor'),
      ref('isEditorialReader'),
      ref('isSonicDeveloper'),
    ),
  ),

  /*** ModelComparisonShowTypeFilter ***/
  canAccessComparisonShowTypeFilter: and(
    ref(SonicFeature.ModelComparisonShowTypeFilter),
    or(
      ref('isSuperAdmin'),
      ref('isEditor'),
      ref('isEditorialReader'),
      ref('isSonicDeveloper'),
    ),
  ),

  /*** Avatars ***/
  // until the app is ready let's keep it available for admin developers only
  canAccessAvatars: and(
    ref('isSuperAdmin'),
    ref('isSonicDeveloper'),
    ref(SonicFeature.Avatars),
  ),

  /*** PPV ***/
  canManagePayPerView: and(
    ref(SonicFeature.PayPerViewLegacy),
    or(ref('isSuperAdmin'), ref('isPPVManager')),
  ),

  canAccessPayPerView: and(
    ref(SonicFeature.PayPerViewLegacy),
    or(
      ref('canManagePayPerView'),
      ref('isCustomerSupport'),
      ref('isBusinessReader'),
    ),
  ),

  // temporary solution due to ingress problems
  canManagePayPerViewBolt: and(
    ref(SonicFeature.PayPerViewBolt),
    or(ref('isSuperAdmin'), ref('isPPVManager')),
  ),

  // temporary solution due to ingress problems
  canAccessPayPerViewBolt: and(
    ref(SonicFeature.PayPerViewBolt),
    or(
      ref('canManagePayPerViewBolt'),
      ref('isCustomerSupport'),
      ref('isBusinessReader'),
    ),
  ),

  canAccessBolt: ref(SonicFeature.BoltCapabilities),

  canDoLogoutEverywhere: or(ref('isSuperAdmin'), ref('isUsersAdmin')),

  canToggleReranking: or(ref('isEditor'), ref('isSiteBuilderManager')),

  canAccessSiteBuilderMyList: ref(SonicFeature.SiteBuilderMyList),
  canAccessSiteBuilderContinueWatching: ref(
    SonicFeature.SiteBuilderContinueWatching,
  ),
  canAccessHorizontalReranking: ref(SonicFeature.HorizontalReranking),
  canAccessCollectionPersonalization: ref(
    SonicFeature.CollectionPersonalization,
  ),
  canAccessLegacySearch: ref(SonicFeature.LegacyContentSearch),
  canAccessVerticalReranking: or(ref('isEditor'), ref('isSiteBuilderManager')),
  canAccessGSM: ref(SonicFeature.GSM),
  canEditGSM: and(
    ref(SonicFeature.GSM),
    or(ref('isSuperAdmin'), ref('isGSMUIApprover')),
  ),
  canAccessCollaborators: ref(SonicFeature.Collaborators),
  canAccessMultiverse: or(
    and({ realm: 'bolt', env: 'test' }, hasAnyRole),
    and({ realm: 'bolt', env: 'int' }, hasAnyRole),
    and(ref(SonicFeature.Multiverse), ref('isMultiverse')),
    and(ref(SonicFeature.Multiverse), ref('isMultiverseAdmin')),
  ),
  canAccessMultiverseReleaseEnroll: and(
    ref(SonicFeature.Multiverse),
    ref('isMultiverseAdmin'),
  ),
  canDownloadStudioUserData: or(ref('isSuperAdmin'), ref('isUsersAdmin')),
  canToggleWholePageOptimization: and(
    ref(SonicFeature.IsPrsEnabled),
    or(ref('isSuperAdmin'), ref('isSiteBuilderManager')),
  ),
  canAccessUserDeviceInfo: and(
    ref('canUpdateUsers'),
    ref(SonicFeature.UserDeviceInfo),
  ),
  canAccessUserDownloadsInfo: and(
    ref('canUpdateUsers'),
    ref(SonicFeature.UserDownloadsInfo),
  ),
  canAccessTerritoryHierarchyDefinition: ref(
    SonicFeature.TerritoryHierarchyDefinition,
  ),

  /** DIANA */
  canAccessDiana: and(
    ref(SonicFeature.Diana),
    or(
      ref('isDianaCategoryManager'),
      ref('isDianaOverrideManager'),
      ref('isDianaOverridePublisher'),
    ),
  ),
  canAccessDianaIpRules: and(
    ref(SonicFeature.Diana),
    or(ref('isDianaOverrideManager'), ref('isDianaOverridePublisher')),
  ),
  canViewDianaIpRules: and(
    ref(SonicFeature.Diana),
    ref('isDianaOverrideManager'),
  ),
  canCreateDianaIpRule: and(
    ref(SonicFeature.Diana),
    or(ref('isDianaOverrideManager'), ref('isDianaOverridePublisher')),
  ),
  canAccessDianaIpCategories: and(
    ref(SonicFeature.Diana),
    ref('isDianaCategoryManager'),
  ),

  canViewStudioRolesWarning: ref(SonicFeature.StudioRolesWarning),
  canAccessPageGraph: or(ref('isSuperAdmin'), ref('isSonicStudioBetaTester')),
  canReportSubscriptionJourney: and(
    ref(SonicFeature.SubscriptionJourney),
    or(ref('canManageSubscriptionJourney'), ref('isBusinessReader')),
  ),
  canReportMonetization: and(
    ref(SonicFeature.MonetizationBolt),
    or(ref('canManageMonetization'), ref('isBusinessReader')),
  ),

  canUseMVPDDefederation: or(
    and({ realm: 'bolt', env: 'prod' }, ref('isSuperAdmin')),
    and(
      or(
        { realm: 'bolt', env: 'test' },
        { realm: 'bolt', env: 'int' },
        { realm: 'bolt', env: 'stage' },
      ),
      ref('canViewUsers'),
    ),
  ),

  /*** Localization widget ***/
  canAccessNewLocalizationWidget: ref(SonicFeature.NewLocalizationWidget),

  canAccessBadges: and(
    ref(SonicFeature.Badges),
    or(
      ref('isSuperAdmin'),
      ref('isTaxonomyManager'),
      ref('isSiteConfigManager'),
    ),
  ),

  canManageBadgeLocalizations: and(
    ref('canAccessBadges'),
    or(ref('isSuperAdmin'), ref('isSiteConfigManager')),
  ),

  canAccessSegments: and(
    ref(SonicFeature.AudienceTargetingFromCustomerSegments),
    or(ref('isSuperAdmin'), ref('isSonicDeveloper'), ref('isSegmentManager')),
  ),
  canUpdateUserMigration: or(
    and(ref(SonicFeature.UpdateUserMigration), ref('isSuperAdmin')),
    and(
      ref(SonicFeature.UpdateUserMigration),
      or({ env: 'test' }, { env: 'int' }, { env: 'stage' }),
      ref('isUsersAdmin'),
    ),
  ),

  canAccessPartnerMapping: and(
    ref(SonicFeature.PartnerMapping),
    or(
      ref('canManagePartnerMapping'),
      ref('isProductManager'),
      ref('isBusinessReader'),
    ),
  ),

  canManagePartnerMapping: and(
    ref(SonicFeature.PartnerMapping),
    or(ref('isSuperAdmin'), ref('isPartnerIntegrationManager')),
  ),

  canUpdateSilhouetteProfile: and(
    ref(SonicFeature.SilhouetteProfile),
    ref('canUpdateUsers'),
  ),
  /*** Multipass App  ***/
  canAccessMultipass: and(ref(SonicFeature.Multipass), ref('isSuperAdmin')),
  /*** ProfileSimulator App  ***/
  canAccessProfileSimulator: and(
    ref(SonicFeature.ProfileSimulator),
    or(
      ref('isSuperAdmin'),
      and(
        ref('isSonicStudioBetaTester'),
        or(ref('isCustomerSupport'), ref('isUsersAdmin')),
      ),
    ),
  ),
  canViewAuthProvidersInfo: and(
    ref(SonicFeature.UserAuthProviders),
    ref('canViewUsers'),
  ),
  /*** Legal Feature Flag ***/
  canAccessElvisLegalFeatureFlag: ref(SonicFeature.ElvisLegalFeatureFlag),
  canAccessFlash: and(
    ref(SonicFeature.Flash),
    or(ref('isCustomerSupport'), ref('isSuperAdmin')),
  ),
  canAccess$AppName: and(
    ref(SonicFeature.$AppName),
    or(ref('isSuperAdmin'), ref('isSonicDeveloper')),
  ),
  canAccessNewLocalizationHelperText: ref(
    SonicFeature.NewLocalizationHelperText,
  ),
  canViewAuthProvidersVerificationStatus: and(
    ref(SonicFeature.AuthProviderVerification),
    ref('canViewUsers'),
  ),

  canEditMosaicFeatures: or(
    ref('isSuperAdmin'),
    ref('isMosaicMartechOperator'),
    ref('isMosaicDataEngineeringAdmin'),
  ),
  canViewMosaicPages: or(
    ref('canEditMosaicFeatures'),
    ref('isMosaicMartechOnLooker'),
  ),
  canAccessMosaic: and(ref(SonicFeature.Mosaic), or(ref('canViewMosaicPages'))),

  /*** Site Reporting ***/
  canAccessSiteReporting: and(
    ref(SonicFeature.SiteReporting),
    or(ref('isSuperAdmin'), ref('isEditor'), ref('isEditorialReader')),
  ),
  canViewNewNavigation: and(
    ref(SonicFeature.NewNavigation),
    ref('isSonicStudioBetaTester'),
  ),

  canAccessPlanBulkActions: and(
    ref(SonicFeature.PlanBulkActions),
    or(ref('isSuperAdmin'), ref('isProductManager')),
  ),

  canAccessMulticonfig: and(
    ref(SonicFeature.Multiconfig),
    or(
      ref('isUsersAdmin'),
      ref('isSuperAdmin'),
      ref('isMultiConfigAdmin'),
      ref('isMultiConfigGeographicManager'),
      ref('isMultiConfigRatingsManager'),
    ),
  ),

  canAccessPartnerPortal: and(
    ref(SonicFeature.PartnerPortal),
    ref('isSuperAdmin'),
  ),
  // 🚨 DON'T REMOVE COMMENT : Register your access above
} as const satisfies Record<string, Rule<AccessContext>>;

const rules = {
  ...roleRules,
  ...featureFlagRules,
  ...permissionRules,
  ...contentPromotionRules,
  ...curationPromotionRules,
} as const satisfies Record<string, Rule<AccessContext>>;

export default rules;

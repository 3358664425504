import { useHistory } from 'react-router';

import { type HybridHistory, isHybridHistory } from './hybridHistory';

export default function useHybridHistory(): HybridHistory {
  const history = useHistory();

  if (!isHybridHistory(history)) {
    console.error({ history });
    throw new Error('useHybridHistory must be used with a hybrid history');
  }

  return history;
}

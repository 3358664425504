import { isObjectOf, isShapeOf, isUnknown } from 'giltig';

export const SECONDARY_EXPERIENCE_KEYS = [
  'subdivisionTenant',
  'subdivisionMarket',
  'publishingSite',
  'commerceLine',
  'productLine',
] as const satisfies string[];

export type SecondaryExperienceKey = (typeof SECONDARY_EXPERIENCE_KEYS)[number];

export const EXPERIENCE_KEYS = [
  'studioExperience',
  ...SECONDARY_EXPERIENCE_KEYS,
] as const;

export type ExperienceKey = (typeof EXPERIENCE_KEYS)[number];

const EXPERIENCE_KEYS_LOOSE: readonly string[] = EXPERIENCE_KEYS;

export function isExperienceKey(value: string): value is ExperienceKey {
  return EXPERIENCE_KEYS_LOOSE.includes(value);
}

export const isObjectWithExperience = isShapeOf({
  experience: isObjectOf(isUnknown),
});

import { useMemo } from 'react';
import { useLocation } from 'react-router';

import { EXPERIENCE_KEYS } from '@studio/utils/experience';
import useEventCallback from '@studio/utils/useEventCallback';

import useNavigate from './useNavigate';

export type SearchParams = Readonly<Record<string, string>>;
type NextSearchParams = ConstructorParameters<typeof URLSearchParams>[0];

type SetParamsFromPrevFn = (prevSearchParams: SearchParams) => NextSearchParams;
type SetSearchParamsArg = NextSearchParams | SetParamsFromPrevFn;

export type SetParamsFn = (params: SetSearchParamsArg) => void;

export default function useSearchParams(): readonly [
  searchParams: SearchParams,
  setSearchParams: SetParamsFn,
] {
  const location = useLocation();
  const navigate = useNavigate();

  const searchParams = useMemo<SearchParams>(
    () => Object.fromEntries(new URLSearchParams(location.search)),
    [location.search],
  );

  const setSearchParams = useEventCallback(
    (
      nextSearchParams:
        | NextSearchParams
        | ((prevSearchParams: SearchParams) => NextSearchParams),
    ) => {
      navigate(
        prevLocation => {
          const prevUrlSearchParams = new URLSearchParams(prevLocation.search);

          const nextUrlSearchParams = new URLSearchParams(
            typeof nextSearchParams === 'function'
              ? nextSearchParams(Object.fromEntries(prevUrlSearchParams))
              : nextSearchParams,
          );

          // Copy over any experience keys that are not in the new search params
          for (const experienceKey of EXPERIENCE_KEYS) {
            const prevExperienceValue = prevUrlSearchParams.get(experienceKey);
            if (
              prevExperienceValue !== null &&
              !nextUrlSearchParams.has(experienceKey)
            ) {
              nextUrlSearchParams.set(experienceKey, prevExperienceValue);
            }
          }

          return { search: nextUrlSearchParams.toString() };
        },
        { mode: 'replace' },
      );
    },
  );

  return [searchParams, setSearchParams] as const;
}

import type { To } from '@studio/router';
import type { RequestContext } from '@studio/utils/requestContext';

import {
  type Experience,
  EXPERIENCE_KEYS,
  isObjectWithExperience,
  type SecondaryExperienceKey,
  type VerifiedExperience,
} from '../../utils/experience';

export function getNextRequestContext(
  prevRequestContext: RequestContext,
  verifiedExperience: VerifiedExperience | null,
): RequestContext {
  return {
    ...prevRequestContext,
    experience: verifiedExperience,
    publishingSiteId: verifiedExperience?.publishingSite ?? null,
  };
}

const EXPERIENCE_KEY_PRIORITY: Readonly<
  Record<SecondaryExperienceKey, number>
> = {
  publishingSite: 0,
  productLine: 1,
  commerceLine: 2,
  subdivisionMarket: 3,
  subdivisionTenant: 4,
};

Object.setPrototypeOf(EXPERIENCE_KEY_PRIORITY, null);

export function secondaryExperienceKeyComparator(
  keyA: SecondaryExperienceKey,
  keyB: SecondaryExperienceKey,
): number {
  const priorityA = EXPERIENCE_KEY_PRIORITY[keyA] ?? Infinity;
  const priorityB = EXPERIENCE_KEY_PRIORITY[keyB] ?? Infinity;
  return priorityA - priorityB;
}

export function getNextStateWithExperience(
  prevState: unknown,
  experience: Experience,
): unknown {
  return {
    ...(typeof prevState === 'object' ? prevState : undefined),
    experience: isObjectWithExperience(prevState)
      ? { ...prevState.experience, ...experience }
      : experience,
  };
}

export function createExperienceLocationUpdater(experience: Experience): To {
  return prevLocation => {
    const nextSearchParams = new URLSearchParams(prevLocation.search);

    for (const experienceKey of EXPERIENCE_KEYS) {
      const value = experience[experienceKey];
      if (value == null) {
        nextSearchParams.delete(experienceKey);
      } else {
        nextSearchParams.set(experienceKey, value);
      }
    }

    return {
      search: nextSearchParams.toString(),
      state: getNextStateWithExperience(prevLocation.state, experience),
    };
  };
}

let mergedExperience: Experience | null = null;
let mergedExperienceTimerHandle: ReturnType<typeof setImmediate> | null = null;

function flushExperienceUpdate(
  callback: (mergedExperience: Experience) => void,
): void {
  if (mergedExperienceTimerHandle != null) {
    clearImmediate(mergedExperienceTimerHandle);
    mergedExperienceTimerHandle = null;
  }

  if (mergedExperience != null) {
    callback(mergedExperience);
    mergedExperience = null;
  }
}

export function scheduleExperienceUpdate(
  nextExperience: Experience,
  callback: (nextExperience: Experience) => void,
): void {
  if (mergedExperienceTimerHandle != null) {
    clearImmediate(mergedExperienceTimerHandle);
    mergedExperienceTimerHandle = null;
  }

  mergedExperienceTimerHandle = setImmediate(flushExperienceUpdate, callback);

  mergedExperience ??= {};

  Object.assign(mergedExperience, nextExperience);
}

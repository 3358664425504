import {
  memo,
  type ReactElement,
  type ReactNode,
  useContext,
  useDeferredValue,
  useLayoutEffect,
  useMemo,
} from 'react';

import {
  type AccessFn,
  isFeatureEnabled,
  SonicFeature,
} from '@studio/api/access';
import customAttributesApi from '@studio/api/custom-attributes';
import type { CurrentUser } from '@studio/api/users';
import { Redirect, useLocation, useNavigate } from '@studio/router';
import Spinner from '@studio/ui-common/Spinner';
import noop from '@studio/utils/noop';
import {
  type RequestContext,
  RequestContextProvider,
  useRequestContext_UNSAFE,
} from '@studio/utils/requestContext';
import useEventCallback from '@studio/utils/useEventCallback';
import useLastEqualValue from '@studio/utils/useLastEqualValue';

import * as appRoutes from '../../appRoutes';
import {
  createExperienceAuthorizationChecker,
  createExperienceRoleResolver,
  type Experience,
  type ExperienceAuthorizationChecker,
  type ExperienceConfig,
  type ExperienceRoleResolver,
  getEligibleExperiences,
  isExperienceKey,
  type PersistedExperience,
  type SecondaryExperienceKey,
  type VerifiedExperience,
} from '../../utils/experience';
import type { PermissionGroups } from '../../utils/permission';
import useAccessChecker from '../../utils/useAccessChecker';
import useAccessContext from '../../utils/useAccessContext';
import useCustomAttributes, {
  type UpdateAttributes,
} from '../../utils/useCustomAttributes';
import { SessionStateStatus, useSessionState } from '../SessionManager';
import ExperienceContext, {
  type ExperienceContextValue,
} from './ExperienceContext';
import {
  createExperienceLocationUpdater,
  getNextRequestContext,
  getNextStateWithExperience,
  scheduleExperienceUpdate,
} from './utils';

const canAlwaysAccess: AccessFn = () => true;

const EMPTY_ARRAY: readonly never[] = [];

export interface ExperienceManagerProps {
  access?: AccessFn;
  enabledExperiences?: readonly SecondaryExperienceKey[];
  noVerifiedExperienceBehavior?: 'redirectToRoot' | 'enterWithNullExperience';
  children: ReactNode;
}

export default memo(function ExperienceManager(
  props: ExperienceManagerProps,
): ReactNode {
  const sessionState = useSessionState();

  if (
    sessionState.status !== SessionStateStatus.LoggedIn ||
    sessionState.experienceConfig == null ||
    sessionState.permissionGroups == null
  ) {
    return props.children;
  }

  const { experienceConfig, permissionGroups, user } = sessionState;

  return (
    <ExperienceManagerInner
      {...props}
      experienceConfig={experienceConfig}
      permissionGroups={permissionGroups}
      user={user}
    />
  );
});

interface ExperienceManagerInnerProps extends ExperienceManagerProps {
  experienceConfig: ExperienceConfig;
  permissionGroups: PermissionGroups;
  user: CurrentUser;
}

const ExperienceManagerInner = memo(function ExperienceManagerInner({
  access,
  enabledExperiences = EMPTY_ARRAY,
  ...props
}: ExperienceManagerInnerProps): ReactElement {
  const parentExperienceConfig = useContext(ExperienceContext);

  const parentAccess = parentExperienceConfig?.access;
  const parentEnabledExperiences = parentExperienceConfig?.enabledExperiences;

  access ??= parentAccess ?? canAlwaysAccess;
  enabledExperiences = useLastEqualValue(
    useMemo(() => {
      if (parentEnabledExperiences == null) {
        return enabledExperiences.toSorted();
      }

      return new Set([...parentEnabledExperiences, ...enabledExperiences])
        .values()
        .toArray()
        .sort();
    }, [enabledExperiences, parentEnabledExperiences]),
  );

  const [
    persistedExperience,
    updatePersistedExperience,
    {
      loading: loadingPersistedExperience,
      updating: updatingPersistedExperience,
    },
  ] = useDeferredValue(useCustomAttributes(customAttributesApi.experience));

  return (
    <>
      <Spinner overlay show={loadingPersistedExperience} />

      {!loadingPersistedExperience && (
        <ExperienceManagerInnerInner
          {...props}
          access={access}
          enabledExperiences={enabledExperiences}
          persistedExperience={persistedExperience}
          onUpdatePersistedExperience={updatePersistedExperience}
          updatingPersistedExperience={updatingPersistedExperience}
        />
      )}
    </>
  );
});

interface ExperienceManagerInnerInnerProps extends ExperienceManagerInnerProps {
  access: AccessFn;
  enabledExperiences: readonly SecondaryExperienceKey[];
  persistedExperience: PersistedExperience | null;
  updatingPersistedExperience: boolean;
  onUpdatePersistedExperience: UpdateAttributes<PersistedExperience, null>;
}

const ExperienceManagerInnerInner = memo(function ExperienceManagerInnerInner({
  user,
  persistedExperience,
  permissionGroups,
  access,
  enabledExperiences,
  experienceConfig,
  updatingPersistedExperience,
  onUpdatePersistedExperience,
  noVerifiedExperienceBehavior = 'redirectToRoot',
  children,
}: ExperienceManagerInnerInnerProps) {
  const accessContext = useAccessContext({
    resolveExperienceRoles: false,
  });

  const unverifiedPersistedExperience = useMemo<Experience>(() => {
    if (persistedExperience?.studioExperience == null) {
      return {};
    }

    return {
      studioExperience: persistedExperience.studioExperience,
      ...persistedExperience.secondaryExperiences[
        persistedExperience.studioExperience
      ],
    };
  }, [persistedExperience]);

  const checkAccess = useAccessChecker();

  const shouldUseExperienceUrls = checkAccess(
    isFeatureEnabled(SonicFeature.ExperienceUrls),
  );

  const navigate = useNavigate();
  const location = useLocation<{
    readonly experience?: Experience;
  }>();

  const unverifiedExperienceFromLocationState = location.state?.experience;

  const unverifiedExperienceFromSearchParams = useMemo<Experience>(() => {
    const searchParams = new URLSearchParams(location.search);
    for (const key of searchParams.keys()) {
      if (!isExperienceKey(key)) {
        searchParams.delete(key);
      }
    }
    return Object.fromEntries(searchParams);
  }, [location.search]);

  const unverifiedExperience = useLastEqualValue<Experience>(
    shouldUseExperienceUrls
      ? {
          ...unverifiedPersistedExperience,
          ...unverifiedExperienceFromLocationState,
          ...unverifiedExperienceFromSearchParams,
        }
      : unverifiedPersistedExperience,
  );

  const resolveExperienceRoles = useMemo<ExperienceRoleResolver>(() => {
    return createExperienceRoleResolver(
      user.legacyRoles,
      user.groups,
      permissionGroups,
    );
  }, [permissionGroups, user.groups, user.legacyRoles]);

  const isAuthorizedExperience = useMemo<ExperienceAuthorizationChecker>(() => {
    return createExperienceAuthorizationChecker(
      access,
      accessContext,
      resolveExperienceRoles,
    );
  }, [access, accessContext, resolveExperienceRoles]);

  const verifiedExperience = useMemo<VerifiedExperience | null>(() => {
    using eligibleExperiences = getEligibleExperiences(
      unverifiedExperience,
      experienceConfig,
      enabledExperiences,
    );

    return eligibleExperiences.find(isAuthorizedExperience) ?? null;
  }, [
    enabledExperiences,
    experienceConfig,
    isAuthorizedExperience,
    unverifiedExperience,
  ]);

  useLayoutEffect(() => {
    if (!shouldUseExperienceUrls) {
      return noop;
    }

    // Always write the full unverified experience back into the experience state
    navigate(
      prevLocation => {
        return {
          // This doesn't result in an infinite loop because we don't
          // update the location if the next location is the same,
          // and the state objects are deeply compared
          state: getNextStateWithExperience(
            prevLocation.state,
            unverifiedExperience,
          ),
        };
      },
      { mode: 'replace' },
    );

    if (verifiedExperience == null) {
      return noop;
    }

    return scheduleExperienceUpdate(verifiedExperience, mergedExperience => {
      navigate(createExperienceLocationUpdater(mergedExperience), {
        mode: 'replace',
      });
    });
  });

  const prevRequestContext = useRequestContext_UNSAFE();

  const nextRequestContext = useMemo<RequestContext>(
    () => getNextRequestContext(prevRequestContext, verifiedExperience),
    [prevRequestContext, verifiedExperience],
  );

  const updateExperience = useEventCallback((nextExperience: Experience) => {
    const {
      studioExperience: nextStudioExperience,
      ...nextSecondaryExperiences
    } = nextExperience;

    if (nextStudioExperience == null) {
      throw new TypeError("You can't clear your Studio experience!");
    }

    if (shouldUseExperienceUrls) {
      navigate(createExperienceLocationUpdater(nextExperience), {
        mode: 'replace',
      });
    }

    onUpdatePersistedExperience(null, prevPersistedExperience => {
      return {
        studioExperience: nextStudioExperience,
        secondaryExperiences: {
          ...prevPersistedExperience?.secondaryExperiences,
          [nextStudioExperience]: {
            ...prevPersistedExperience?.secondaryExperiences[
              nextStudioExperience
            ],
            ...nextSecondaryExperiences,
          },
        },
      };
    });
  });

  const nextExperienceContextValue = useMemo<ExperienceContextValue>(
    () => ({
      access,
      experience: verifiedExperience,
      experienceConfig,
      enabledExperiences,
      updatingExperience: updatingPersistedExperience,
      updateExperience,
    }),
    [
      access,
      verifiedExperience,
      experienceConfig,
      enabledExperiences,
      updatingPersistedExperience,
      updateExperience,
    ],
  );

  if (
    verifiedExperience === null &&
    noVerifiedExperienceBehavior === 'redirectToRoot'
  ) {
    return <Redirect to={appRoutes.portalRoot()} />;
  }

  return (
    <ExperienceContext.Provider value={nextExperienceContextValue}>
      <RequestContextProvider value={nextRequestContext}>
        {children}
      </RequestContextProvider>
    </ExperienceContext.Provider>
  );
});

import { locationsAreEqual } from 'history';

import { memoizeWeakKey } from '@studio/utils/memoize';

import type { HybridHistory } from './hybridHistory';
import useHybridHistory from './useHybridHistory';
import { resolveLocation, type To } from './utils';

export interface NavigateOptions {
  readonly mode?: 'push' | 'pop' | 'replace';
}

export interface NavigateFunction {
  (to: To, options?: NavigateOptions): void;
  (delta: number): void;
}

const getNavigateFunction = memoizeWeakKey(
  (history: HybridHistory): NavigateFunction => {
    return function navigate(to, options?: NavigateOptions) {
      if (typeof to === 'number') {
        history.go(to);
      } else {
        const mode = options?.mode ?? 'push';

        let nextLocation = resolveLocation(history.location, to);

        // Always use the current location key for replace
        if (mode === 'replace' && nextLocation.key !== history.location.key) {
          nextLocation = { ...nextLocation, key: history.location.key };
        }

        if (locationsAreEqual(history.location, nextLocation)) {
          return;
        }

        if (mode === 'push') {
          history.push(nextLocation);
        } else if (mode === 'pop') {
          history.pop(nextLocation);
        } else if (mode === 'replace') {
          history.replace(nextLocation);
        }
      }
    };
  },
);

export default function useNavigate(): NavigateFunction {
  const history = useHybridHistory();
  return getNavigateFunction(history);
}
